<script setup lang="ts">
interface Props {
  title: string
  alignment?: string
  gridColumn?: string
  src?: string
  flexDirection?: string
}

withDefaults(defineProps<Props>(), {
  alignment: "left",
  gridColumn: '1 / 2',
  src: 'join',
  flexDirection: 'column',
})

const validationSchema = useValidation(['email'])

async function submit(body: CommonFormBody) {
  await useRedirectFromForm(body)
}
</script>

<template>
  <section class="join">
    <div class="join__form">
      <CommonForm 
        :title="title"
        type="email"
        name="email"
        :placeholder="$t('homePage.joinPlaceholder')"
        :buttonName="$t('homePage.joinButton')"
        :alignment="alignment"
        :validationSchema="validationSchema"
        @submit-form="submit"
      />
    </div>
    <img 
      :src="`/img/home/${src}.webp`" 
      alt="join"
      width="644"
      class="join__img"
    >
    <img 
      :src="`/img/home/${src}-mobile.webp`" 
      alt="join"
      width="351"
      class="join__img-mobile"
    >
  </section>
</template>

<style lang='scss' scoped>
.join {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 28px;

  @media (width < 1024px) {
    display: flex;
    flex-direction: v-bind(flexDirection);
  }

  &__form {
    max-height: 310px;
    padding: 70px 66px;
    border-radius: var(--border-radius);
    background: var(--c-borders-secondary);
    grid-column: v-bind(gridColumn);
    grid-row: 1 / 1;

    @media (width < 1024px) {
      padding: 24px 12px;
    }
  }

  &__img {
    aspect-ratio: 1.89;
    grid-row: 1 / 1;

    @media (width < 700px) {
      display: none;
    }  
  }

  &__img-mobile {
    display: none;
    aspect-ratio: 1.47;

    @media (width < 700px) {
      display: block;
      width: 100%;
    }  
  }
}
</style>
