<script setup>
const showVideo = ref(false)
const playVideo = () => {
  showVideo.value = true
}
</script>

<template>
  <section class="work">
    <h3 class="work__title">{{ $t('homePage.workTitle') }}</h3>
    <p class="work__text">{{ $t('homePage.workText') }}</p>
    <div
      class="work__container"
      @click="playVideo"
    >
      <iframe
        v-if="showVideo"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/59Gkmh7QAX4?autoplay=1"
        allowfullscreen
        allow="autoplay; encrypted-media"
      ></iframe>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.work {
  &__title {
    font-size: 32px;
    font-weight: 600;
    margin-block-end: 12px;

    @media (width < 700px) {
      font-size: 20px;
      text-align: center;
      text-transform: uppercase;
    }
  }

  &__text {
    color: var(--c-text-secondary);
    font-weight: 600;
    margin-block-end: 40px;

    @media (width < 700px) {
      text-align: center;
    }
  }

  &__container {
    cursor: pointer;
    width: 100%;
    aspect-ratio: 2.61;
    border-radius: var(--border-radius);
    display: flex;
    justify-content: center;
    align-items: center;
    background: no-repeat center / cover url('/img/home/work.webp');

    @media (width < 700px) {
      aspect-ratio: 1.78;
      background: no-repeat center / cover url('/img/home/work-mobile.webp');
    }
  }
}
</style>
