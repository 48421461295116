<script setup lang="ts">
import { YandexMap, YandexMarker } from 'vue-yandex-maps';
const configStore = useConfigStore()

const data = [
  {
    phone: '8 925 0 377 377',
    email: 'info@m3.sale',
  },
  {
    phone: '8 929 926 46 96',
    email: 'ivan@m3.sale',
  },
]

const settings = {
  apiKey: configStore.env.apiKeyMaps as string,
  debug: false,
  version: '2.1',
}
const markerOptions = {
  iconLayout: 'default#image',
  iconImageHref: 'https://m3.sale/svg/common/logo.svg',
  iconImageSize: [75, 30],
  iconImageOffset: [-20, 0],
}
</script>

<template>
  <section class="contacts">
    <h2 class="contacts__title home__title">
      {{ $t('homePage.contactsTitle') }}
    </h2>
    <div class="contacts__content">
      <div class="contacts__map">
        <client-only>
          <YandexMap
            style="width: 100%; height: 100%"
            :settings="settings"
            :coordinates="[57.980684, 33.246416]"
            :zoom="6"
            :controls="['zoomControl', 'fullscreenControl']"
          >
            <YandexMarker
              :coordinates="[59.987132, 30.312985]"
              :markerId="1"
              :options="markerOptions"
            />
            <YandexMarker
              :coordinates="[55.736402, 37.694989]"
              :markerId="1"
              :options="markerOptions"
            />
          </YandexMap>
        </client-only>
      </div>
      <div class="contacts__block">
        <article
          v-for="(item, idx) in data"
          :key="item.phone"
          class="contacts__card card"
        >
          <h4 class="card__title">{{ $t(`homePage.contacts[${idx}].city`) }}</h4>
          <ClientOnly>
            <div class="card__item">
              <img
                src="/svg/home/phone.svg"
                alt="phone"
                class="card__icon" 
              />
              <span class="card__text">{{ item.phone }}</span>
            </div>
            <div class="card__item">
              <img
                src="/svg/home/mail.svg"
                alt="e-mail"
                class="card__icon" 
              />
              <span class="card__text">{{ item.email }}</span>
            </div>
            <div class="card__item">
              <img
                src="/svg/home/location.svg"
                alt="location"
                class="card__icon" 
              />
              <span class="card__text">{{ $t(`homePage.contacts[${idx}].location`)  }}</span>
            </div>
          </ClientOnly>
        </article>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.contacts {
  &__title {
    margin-block-end: 60px;

    @media (width < 700px) {
      margin-block-end: 24px;
    }
  }

  &__content {
    display: flex;
    justify-content: center;
    align-items: stretch;

    @media (width < 700px) {
      flex-direction: column;
      align-items: center;
    }
  }

  &__map {
    position: relative;
    background: var(--c-grey-4);
    display: flex;
    justify-content: center;
    flex: 2 1 auto;
    border-radius: 60px;
    overflow: hidden;
    height: 480px;
    width: 100%;
  }

  &__block {
    display: flex;
    flex-direction: column;
    gap: 20px;
    background: var(--c-brand);
    width: max-content;
    padding: 61px 38px 34px;
    border-radius: 60px;
    flex-shrink: 0;

    @media(width < 1024px) {
      width:100%;
    }
    @media (width < 700px) {
      padding: 50px 24px 20px;
    }
  }
}
.card {
  display: flex;
  flex-direction: column;
  gap: 14px;
  margin-block-end: 30px;

  &__title {
    font-size: 20px;
    font-weight: 500;
    margin-block-end: 8px;

    @media (width < 700px) {
      text-align: center;
      font-size: 16px;
      font-weight: 600;
    }
  }

  &__item {
    display: flex;
    gap: 16px;
  }

  &__icon {
    width: 24px;
    height: 24px;
  }

  &__text {
    font-size: 18px;

    @media (width < 700px) {
      font-size: 14px;
      font-weight: 600;
    }
  }
}
.ymaps-2-1-79-map {
  width: 100%;
}
</style>
